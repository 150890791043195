<template>
  <div class="pt-8">
    <Search v-on:child-method="searchKeywordFn"/>
    <main>
      <div class="dark:bg-gray-900 max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <ModList :files="files" />
      </div>
    </main>
  </div>
</template>

<script>
import ModList from "@/components/Mods/ModList.vue";
import Search from "@/components/Search.vue";
import axios from "axios";
import { debounce } from "lodash";

export default {
  name: "Mods",
  components: {
    ModList,
    Search,
  },
  created() {

    this.debounceSearch = debounce(this.fetchMods, 0);
  },
  data() {
    return {
      searchKeyword: "",
      files: [],
    };
  },
  methods: {
    async fetchMods() {
      try {
        const url = `https://mohdb-api.appelpitje.be/mods/search/`;
        // const url = `http://localhost:8000/mods/search/`;
        const response = await axios.post(url, {
          name: this.searchKeyword,
        });
        const results = response.data;
        this.files = [];
        this.files = response.data;
      } catch (err) {
        if (err.response) {
          console.log("Server Error:", err);
        } else if (err.request) {
          console.log("Network Error:", err);
        } else {
          console.log("Client Error:", err);
        }
      }
    },
    async fetchModsHome() {
      try {
        const url = `https://mohdb-api.appelpitje.be/mods/home/`;
        // const url = `http://localhost:8000/mods/home/`;
        const response = await axios.get(url);
        const results = response.data;
        this.files = response.data;
      } catch (err) {
        if (err.response) {
          console.log("Server Error:", err);
        } else if (err.request) {
          console.log("Network Error:", err);
        } else {
          console.log("Client Error:", err);
        }
      }
    },
    searchKeywordFn(search_input) {
      console.log(search_input)
      this.searchKeyword = search_input;
      if(this.searchKeyword == "") this.fetchModsHome();
      if (!this.searchKeyword || this.searchKeyword.length <= 2) return;
      this.debounceSearch();
    },
  },
  // watch: {
    // searchKeyword() {
    //   console.log(this.searchKeyword)
    //   if(this.searchKeyword == "") this.fetchModsHome();
    //   if (!this.searchKeyword || this.searchKeyword.length <= 3) return;
    //   this.debounceSearch();
    // },
  // },
  mounted() {
    this.fetchModsHome();
  },
};
</script>
