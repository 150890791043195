<template>
  <header class="dark:bg-gray-900">
    <div class="shadow dark:bg-gray-900 dark:border-white border rounded flex">
      <input
        class="w-full dark:bg-gray-800 dark:placeholder-white dark:text-white p-2"
        type="text"
        placeholder="Search..."
        v-model="searchKeyword" 
        v-on:keyup.enter="sendToParent"
      />
      <button
        class="w-auto flex justify-end items-center text-blue p-2 hover:text-blue-light" @click="sendToParent"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          class="flex-shrink-0 h-6 w-6 text-gray-500 dark:text-white"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>
      </button>
    </div>
  </header>
</template>

<script>
export default {
  // props: ['searchKeyword']
  data () {
    return {
      searchKeyword: ""
    }
  },
  methods: {
    sendToParent: function() {
      // console.log(this.searchKeyword)
       this.$emit('child-method',this.searchKeyword)
    }
  }
};
</script>

<style>
</style>