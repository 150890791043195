<template>
  <tr class="dark:bg-gray-800">
    <td class="px-6 py-4 whitespace-nowrap">
      <div class="flex items-center">
        <div v-if="file.map_screens" class="flex-shrink-0 h-20 w-20" >
          <img
            :src="file.map_screens[0].files.path"
            alt=""
            class="h-full"
          />
        </div>
      </div>
    </td>
    <td class="">
      <div class="text-sm text-gray-900 dark:text-white">{{ file.title }}</div>
    </td>
    <td class="">
      <div class="text-sm font-medium text-gray-900 dark:text-white">{{ file.creator }}</div>
    </td>
    <td class="text-sm text-gray-500 dark:text-white ">{{ file.desc | strippedContent }}</td>
    <td class="text-center">
      <a :href="file.map_screens[0].files.path" class="inline-block align-middle"
        ><svg
          xmlns="http://www.w3.org/2000/svg"
          class="flex-shrink-0 h-6 w-6 text-blue-400	 hover:text-blue-700"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
          /></svg
      ></a>
    </td>
  </tr>
</template>

<script>
export default {
  name: "MapListItem",
    props: {
    file: {
      type: Object,
      required: true,
    },
  },
  filters: {
    strippedContent: function(string) {
           return string.replace(/<\/?[^>]+>/ig, " "); 
    }
}
};
</script>

<style>
</style>